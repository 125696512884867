
import { ref } from 'vue';
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FormSelect from '@/components/form/Select/index.vue';
import formValidate from '@/lib/formFactory/validate';
import TitleReturn from '@/components/TitleReturn.vue';

import UsersModule from '@/store/settings/users';
import SettingsModule from '@/store/settings';
import AppTable from '@/components/ui/table/Table.vue';
import IconFont from '@/components/icons/IconFont.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    StatusActive,
    IconFont,
    AppTable,
    TabsNav,
    AppHeaderBtnAdd,
    ArrowReturn,
    AppHeaderMain,
    FormSelect,
    FormSimpleSwitch,
    TitleReturn,
  },
})
export default class UsersMain extends Vue {
  form = ref(null);
  formValidate = formValidate(this.form);

  get titlePage() {
    return UsersModule.pageSettings.titleEdit;
  }

  get tabsNav() {
    return SettingsModule.tabsNav;
  }

  get currentTab() {
    return SettingsModule.allTabs.users?.id;
  }

  get settings() {
    return UsersModule.pageSettings;
  }

  get filterSettings() {
    return UsersModule.filter.filterSettings;
  }

  toAdd() {
    this.$router.push({ name: 'user_add' });
  }

  selectAmount(value: string) {
    UsersModule.updatePageAmountItems(value);
  }

  async updateList() {
    await UsersModule.setPageToBegin();
    await UsersModule.filter.updateFilter();
    await UsersModule.getList();
  }

  sort(header: { id: string; sort: { value: string } }) {
    UsersModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async resetFilters() {
    await UsersModule.setPageToBegin();
    await UsersModule.clearSort();
    await UsersModule.filter.resetFilter();
    await UsersModule.getList();
  }

  mounted() {
    SettingsModule.initTabsItems();

    UsersModule.init();
  }
}
