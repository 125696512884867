import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }
const _hoisted_3 = {
  ref: "filter",
  class: "cp-flex cp-flex--justify-between align-items-end cp-margin__b--small-14"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_status_active = _resolveComponent("status-active")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-page__settings"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_header_btn_add, { onClick: _ctx.toAdd }, null, 8, ["onClick"])
            ])
          ]),
          _createElementVNode("form", _hoisted_3, [
            _createVNode(_component_app_header_main, {
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onUpdateList: _ctx.updateList,
              onResetFilters: _ctx.resetFilters
            }, null, 8, ["filter-settings", "onUpdateList", "onResetFilters"])
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-securityGroup": _withCtx(({ value }) => [
              _createTextVNode(_toDisplayString(value ? value.name : '-'), 1)
            ]),
            "item-fullName": _withCtx(({ item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: { name: 'user_edit', params: { id: item.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`${item.lastName} ${item.firstName}`.trim() || item.login), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-partner": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "app-table-link",
                    to: { name: 'partner_edit', params: { partnerId: value.uuid } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value.legalName), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode("-")
                  ], 64))
            ]),
            "item-isActive": _withCtx(({ value }) => [
              _createVNode(_component_status_active, { "is-active": value }, null, 8, ["is-active"])
            ]),
            "item-chiefState": _withCtx(({ value }) => [
              _createTextVNode(_toDisplayString(value ? 'Да' : '-'), 1)
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}